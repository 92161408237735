import React from "react"
import Layout from "../components/Layout"
import Hero from "../components/Hero"
import About from "../components/About"
import Services from "../components/Services"
import Works from "../components/Works"
import Process from "../components/Process"
import Question from "../components/Question"
import Faq from "../components/Faq"
import Seo from "../components/SeoGlowna"
import TwoImg from "../components/TwoImg"
const indexpage = () => (
  <Layout>
    <div>
      <Seo></Seo>
      <Hero></Hero>
      <About></About>
      <Services></Services>
      <Works></Works>
      <Process></Process>
      <TwoImg></TwoImg>
      <Question></Question>
      <Faq></Faq>
    </div>
  </Layout> 
) 

export default indexpage;
 