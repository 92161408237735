import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Link } from "gatsby"
import { Container, Row, Col } from 'reactstrap';

const Process = () => {
  const { wpPage } = useStaticQuery(graphql`
  {
    wpPage(slug: {eq: "home"}) {
      home {
        processBackground {
          sourceUrl
        }
        processTitle
        processTitleTag
        worksButton
        worksTitle
        processStages {
          icon
          text
          title
          titleTag
        }
      }
    }
  }
  `)
  const [count, setCount] = useState(1);
  const handleIncrement = (index) =>{
    setCount(index+1);
  }

const Text = wpPage.home.processTitleTag;

  return (
    <div className="process" id="howwework" style={{ backgroundImage: `url(${wpPage.home.processBackground.sourceUrl})` }}>
      <Container className="process-cont">
        <Text className="section-title cent process-title">{wpPage.home.processTitle}</Text>
        <Row className="process-row">
          <div className="process-line"></div>
          {wpPage.home.processStages.map((val, index) => {
         
          if(index+1 == count){ return ( <Col lg="3" className="active">
              <div className={"process-box " + "process-box-" + index} key={index} onMouseEnter={(e) => handleIncrement(index)}>
                <span className={"process-box-icon " + val.icon}></span>
                <div className="process-circle"></div>
                <div className="process-box-content">
                  <val.titleTag className="process-box-title">{val.title}</val.titleTag>
                  <p className="process-box-text">{val.text}</p>
                </div>
              </div>
            </Col>);
          }else{
            return ( <Col lg="3" className="active">
            <div className={"process-box " + "process-box-" + index} key={index}  onMouseEnter={(e) => handleIncrement(index)}>
              <span className={"process-box-icon " + val.icon}></span>
              <div className="process-circle"></div>
              <div className="process-box-content">
                <val.titleTag className="process-box-title">{val.title}</val.titleTag>
                <p className="process-box-text">{val.text}</p>
              </div>
            </div>
          </Col>);
          }
        }
          )}
        </Row>
      </Container>

    </div>
  )
}

export default Process
