import React, { useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
class SectionTabs extends React.Component {
  constructor(props) {
    super(props);
    this.akordeon = this.akordeon.bind(this);
  }
  akordeon(event) {
       event.preventDefault();
      if (!event.target.classList.contains('accordiontab-toggle')) return;
      var content = document.querySelector(event.target.attributes.test.value);
      var toggler = event.target;
      if (!content) return;

      if(content.classList.contains('active')) {
        console.log('not changed');
      } else {
        var accordions = document.querySelectorAll('.accordiontab-content.active');
        for (var i = 0; i < accordions.length; i++) {
          accordions[i].classList.remove('active');
        }
        setTimeout(function() {
          content.classList.toggle('active');
        }, 200);
      }


      var accordionss = document.querySelectorAll('.accordiontab-toggle.active');
      for (var i = 0; i < accordionss.length; i++) {
        accordionss[i].classList.remove('active');
      }
      toggler.classList.toggle('active');
    }

    getShit(){
      const cards = this.props.faq.map((faq, index) => {
       if(index === 0) {
        return ( 
          <>
           <Col lg="6">
         <div className='accordion-title-box'>
              <a key={index} href="" test={['#contenttab-' + index]} onClick={(e) => this.akordeon(e)} className="accordiontab-toggle acctab-button tabtitle active">{faq.question}</a>
              </div>
              </Col>
              <Col lg="6">
                <div className='acordion-content-box'>          
              <div className='acordeon-contents'>
                  <div className="accordiontab-content active" id={['contenttab-' + index]} dangerouslySetInnerHTML={{__html: faq.answer}}>
                     
                  </div>
              </div>
              </div>
        </Col>
          </>
      );
       } else {
        return ( 
          <>
             <Col lg="6">
         <div className='accordion-title-box'>
              <a key={index} href="" test={['#contenttab-' + index]} onClick={(e) => this.akordeon(e)} className="accordiontab-toggle acctab-button tabtitle">{faq.question}</a>
              </div>
              </Col>
              <Col lg="6">
                <div className='acordion-content-box'>          
              <div className='acordeon-contents'>
                  <div className="accordiontab-content" id={['contenttab-' + index]} dangerouslySetInnerHTML={{__html: faq.answer}}>
                      
                  </div>
              </div>
              </div>
        </Col>
          </>
      );
       }
    });
    return cards;
    }



  render() {

      return (
     <Row>
    
       
          {this.getShit()}
          
        </Row>
      );
    }
  }


export default SectionTabs;