import React from "react"
import { useStaticQuery, StaticQuery, graphql } from "gatsby"
import { Container, Row, Col } from 'reactstrap';
import Slider from "react-slick";
import ImgTextLeft from "./ImgTextLeft"

export default class AsNavFor extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        nav1: null,
        nav2: null,
        navcurr: 0,
        curr: 0,
      };
    }
   
    componentDidMount() {
      this.setState({
        nav1: this.slider1,
        nav2: this.slider2
      });  
    
   
    }
  
    render() {
        const settingsnac = {
            beforeChange: (current, next) =>
            this.setState({navcurr: next })
         
        };
        const settings = {
            beforeChange: (current, next) =>
            this.setState({curr: next })
         
        };
      
      return (
        <div>
            <Container>
           <h2 className="about-title cent">Our services</h2>

          <Slider
            asNavFor={this.state.nav1}
            ref={slider => (this.slider2 = slider)}
            slidesToShow={7}
            swipeToSlide={true}
           autoplay={true}
           autoplaySpeed={5000}
            focusOnSelect={true}
            pauseOnHover={false}
            responsive= {[
                {
                  breakpoint: 991,
                  settings: {
                    autoplaySpeed:5000,
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                  
                  }
                },
            ]}
            {...settingsnac}
            className={"navSlider curr" + this.state.navcurr}
          >
               <div class="iconbox">
            <div class="iconboxslider">
              <span class="icon-flat-roofs"></span>
              <span className="services-heading">FLAT ROOF</span>

            </div>
            </div>
            <div class="iconbox">
            <div class="iconboxslider">
            <span class="icon-teraces"></span>
            <span className="services-heading">TERRACE</span>
           
            </div>
            </div>
            <div class="iconbox">
            <div class="iconboxslider">
            <span class="icon-green-roofs"></span>
            <span className="services-heading">GREEN ROOF</span>
           
            </div>
            </div>
            <div class="iconbox">
            <div class="iconboxslider">
            <span class="icon-bathrooms"></span>
            <span className="services-heading">BATHROOM</span>
           
            </div>
            </div>
            <div class="iconbox">
            <div class="iconboxslider">
            <span class="icon-garages"></span>
            <span className="services-heading">GARAGE</span>
           
            </div>
            </div>
            <div class="iconbox">
            <div class="iconboxslider">
            <span class="icon-pools"></span>
            <span className="services-heading">POOL</span>
            </div>
            </div>
            <div class="iconbox">
            <div class="iconboxslider">
            <span class="icon-basements"></span>
            <span className="services-heading">BASEMENT</span>
            </div>
            </div>
          </Slider>
          </Container>
  
           
            <StaticQuery
      query={graphql`
      query Bathroom {
        wpPage(title: {eq: "Home"}) {
            bathroom {
              bathroomBtnLink
              bathroomHeading
              bathroomHeadingTag
              bathroomText
              bathroomBtnText
              bathroomImg {
                altText
                sourceUrl
              }
            }
            basement {
              basementBtnLink
              basementBtnText
              basementHeading
              basementHeadingTag
              basementImg {
                altText
                sourceUrl
              }
              basementText
            }
            flatroof {
              flatRoofBtnLink
              flatRoofBtnText
              flatRoofHeading
              flatRoofHeadingTag
              flatRoofText
              flatRoofImg {
                altText
                sourceUrl
              }
            }
            garage {
              garageBtnLink
              garageBtnText
              garageHeading
              garageHeadingTag
              garageText
              garageImg {
                altText
                sourceUrl
              }
            }
            pool {
              poolBtnLink
              poolBtnText
              poolHeading
              poolHeadingTag
              poolImg {
                altText
                sourceUrl
              }
              poolText
            }
            greenroof {
              greenRoofBtnLink
              greenRoofBtnText
              greenRoofHeading
              greenRoofHeadingTag
              greenRoofText
              greenRoofImg {
                altText
                sourceUrl
              }
            }
            terace {
              teraceBtnLink
              teraceHeading
              teraceBtnText
              teraceHeadingTag
              teraceText
              teraceImg {
                altText
                sourceUrl
              }
            }
          }
        
      }
      
      `}
      render={data => (
          <>
            <Slider
            asNavFor={this.state.nav2}
            ref={slider => (this.slider1 = slider)}
            {...settings}
            swipeToSlide={true}
            focusOnSelect={true}
          >
        <ImgTextLeft obrazek={data.wpPage.flatroof.flatRoofImg.sourceUrl} alt={data.wpPage.flatroof.flatRoofImg.altText} text={data.wpPage.flatroof.flatRoofText} heading={data.wpPage.flatroof.flatRoofHeading} headingtag={data.wpPage.flatroof.flatRoofHeadingTag} btntext={data.wpPage.flatroof.flatRoofBtnText} btnlink={data.wpPage.flatroof.flatRoofBtnLink} ></ImgTextLeft>
        <ImgTextLeft obrazek={data.wpPage.terace.teraceImg.sourceUrl} alt={data.wpPage.terace.teraceImg.altText} text={data.wpPage.terace.teraceText} heading={data.wpPage.terace.teraceHeading} headingtag={data.wpPage.terace.teraceHeadingTag} btntext={data.wpPage.terace.teraceBtnText} btnlink={data.wpPage.terace.teraceBtnLink} ></ImgTextLeft> 
        <ImgTextLeft obrazek={data.wpPage.greenroof.greenRoofImg.sourceUrl} alt={data.wpPage.greenroof.greenRoofImg.altText} text={data.wpPage.greenroof.greenRoofText} heading={data.wpPage.greenroof.greenRoofHeading} headingtag={data.wpPage.greenroof.greenRoofHeadingTag} btntext={data.wpPage.greenroof.greenRoofBtnText} btnlink={data.wpPage.greenroof.greenRoofBtnLink} ></ImgTextLeft>             
        <ImgTextLeft obrazek={data.wpPage.bathroom.bathroomImg.sourceUrl} alt={data.wpPage.bathroom.bathroomImg.altText} text={data.wpPage.bathroom.bathroomText} heading={data.wpPage.bathroom.bathroomHeading} headingtag={data.wpPage.bathroom.bathroomHeadingTag} btntext={data.wpPage.bathroom.bathroomBtnText} btnlink={data.wpPage.bathroom.bathroomBtnLink} ></ImgTextLeft>
        <ImgTextLeft obrazek={data.wpPage.garage.garageImg.sourceUrl} alt={data.wpPage.garage.garageImg.altText} text={data.wpPage.garage.garageText} heading={data.wpPage.garage.garageHeading} headingtag={data.wpPage.garage.garageHeadingTag} btntext={data.wpPage.garage.garageBtnText} btnlink={data.wpPage.garage.garageBtnLink} ></ImgTextLeft>
        <ImgTextLeft obrazek={data.wpPage.pool.poolImg.sourceUrl} alt={data.wpPage.pool.poolImg.altText} text={data.wpPage.pool.poolText} heading={data.wpPage.pool.poolHeading} headingtag={data.wpPage.pool.poolHeadingTag} btntext={data.wpPage.pool.poolBtnText} btnlink={data.wpPage.pool.poolBtnLink} ></ImgTextLeft>
        <ImgTextLeft obrazek={data.wpPage.basement.basementImg.sourceUrl} alt={data.wpPage.basement.basementImg.altText} text={data.wpPage.basement.basementText} heading={data.wpPage.basement.basementHeading} headingtag={data.wpPage.basement.basementHeadingTag} btntext={data.wpPage.basement.basementBtnText} btnlink={data.wpPage.basement.basementBtnLink} ></ImgTextLeft>
        </Slider>
                             </>
      )}
    />
           
         
        </div>
      );
    }
  }