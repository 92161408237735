import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Akordeon from "./Akordeon"
import { Container, Row, Col } from 'reactstrap';
const Template = () => {
  const { wpPage } = useStaticQuery(graphql`
    {
        wpPage(title: {eq: "Home"}) {
            faq {
              faq {
                answer
                question
              }
              faqHeading
              faqHeadingTag
            }
          }
    }
  `)

  return (
    <Container className="faq">
        <wpPage.faq.faqHeadingTag>{wpPage.faq.faqHeading}</wpPage.faq.faqHeadingTag>
        <Akordeon faq={wpPage.faq.faq}></Akordeon>
    </Container>
  )
}

export default Template
