import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Link } from "gatsby"
import { Container, Row, Col } from 'reactstrap';

const Hero = () => {
  const { wpPage } = useStaticQuery(graphql`
  {
    wpPage(slug: {eq: "home"}) {
      home {
        aboutText
        aboutTitle
        aboutTitleTag
        aboutIconbox {
          text
          textTag
          icon
        }
        aboutImg {
          altText
          mediaItemUrl
        }
      }
    }
  }
  `)
const Titletag = wpPage.home.aboutTitleTag;

  return (
    <div className="about">
      <Container>
        <Row>
          <Col lg="6">
            <Titletag className="about-title" id="about-us">{wpPage.home.aboutTitle}</Titletag>
            <div className="about-text" dangerouslySetInnerHTML={{__html: wpPage.home.aboutText}}></div>
            <div className="about-icon-cont">
              {wpPage.home.aboutIconbox.map((val, index) =>
                <div className="about-icon-box" key={index}>
                  <span className={"about-icon " + val.icon}></span>
                  <val.textTag className="about-icon-text">{val.text}</val.textTag>
                </div>
              )}
            </div>
          </Col>
        </Row>
      </Container>
      <img className="about-image" src={wpPage.home.aboutImg.mediaItemUrl} alt={wpPage.home.aboutImg.altText}></img>
    </div>
  )
}

export default Hero
