import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import {Helmet} from "react-helmet";

const Template = () => {
  const { wpPage } = useStaticQuery(graphql`
    {
      wpPage(title: {eq: "Home"}) {
        id
        seo {
          title
          description
        }
      }
    }
  `)

  return (
    <Helmet>
    <title>{wpPage.seo.title}</title>
    <meta name="description" content={wpPage.seo.description} />
</Helmet>

  )
}

export default Template
