import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Link } from "gatsby"
import { Container, Row, Col } from 'reactstrap';
import Arrow from "../assets/svg/arrow-down.inline.svg"
import UniversalLink from "./UniversalLink"

const Hero = () => {
  const { wpPage } = useStaticQuery(graphql`
  {
    wpPage(slug: {eq: "home"}) {
      home {
        heroTitleTag
        heroTitle
        heroText
        heroScrollText
        heroButtons {
          link
          title
        }
        heroBackground {
          sourceUrl
          altText
        }
      }
    }
  }
  `)
const Text = wpPage.home.heroTitleTag;
  return (
    <div className="hero" style={{ backgroundImage: `url(${wpPage.home.heroBackground.sourceUrl})` }}>
      <div className="hero-box">
        <Text className="hero-title">{wpPage.home.heroTitle}</Text>
        <p className="hero-text">{wpPage.home.heroText}</p>
        <div className="hero-buttons">
          {wpPage.home.heroButtons.map((val, index) =>
            <UniversalLink to={val.link} key={index} className="main-button">{val.title}</UniversalLink>
          )}
        </div>
      </div>
      <div className="hero-scrollbox">
        <UniversalLink to="#about-us" className="hero-scroll-link">
          <div className="hero-scroll">
            <p className="hero-scrollbox-text">{wpPage.home.heroScrollText}</p>
            <Arrow className="hero-scrollbox-arrow"></Arrow>
          </div>
        </UniversalLink>
      </div>
    </div>
  )
}

export default Hero
