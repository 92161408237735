import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Container, Row, Col } from 'reactstrap';
const Template = () => {
  const { wpPage } = useStaticQuery(graphql`
  {
    wpPage(slug: {eq: "home"}) {
      home {
        twoimgSubtitleTwo
        twoimgSubtitleTwoTag
        twoimgTitleOne
        twoimgTitleOneTag
        twoimgTitleTwo
        twoimgTitleTwoTag
      }
    }
  }
  
  `)
const Titleonetag = wpPage.home.twoimgTitleOneTag;
const Titletwotag = wpPage.home.twoimgTitleTwoTag;
const Subtitletwotag = wpPage.home.twoimgSubtitleTwoTag;

  return (
    <Container className="twoimg" id="installation">
    <h2 class="about-title cent">Two ways for membrane installation</h2>
      <Row>
          <Col className="twoimg-col" lg="6">
            <Titleonetag className="twoimg-title">{wpPage.home.twoimgTitleOne}</Titleonetag>
            <img className="twoimg-left" src="/static/roof1_kolor-266f08d4f64dab8830770a0d846c45fd.png"></img>
          </Col>
          <Col className="twoimg-col" lg="6">
            <Titletwotag className="twoimg-title">{wpPage.home.twoimgTitleTwo}</Titletwotag>
           
            <img className="twoimg-right" src="/static/roof2_kolor-341d0455ac53fbf8d1f332aad1193c0a.png"></img>
          </Col>
      </Row>
    </Container>
  )
}

export default Template
